import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { getRejectionError } from '../../../../services/request'
import { initialState as initialFilterState, FilterState } from '../../../../state/filter/reducers'
import { ApplicationError } from '../../../../store'
import { AccountActivitySummary } from '../../../../types/api'
import { AccountActivityResponse, requestUsers } from './thunks'
import * as filter from '../../../../state/filter/reducers'

export type AccountActivityState = FilterState & {
  isFetching: boolean
  hasSearched: boolean
  withFilters: boolean
  records: AccountActivitySummary[]
  totalRecords: number
  currentPage: number
  pageSize: number
  error: string | null
}

export const initialState: AccountActivityState = {
  ...initialFilterState,
  isFetching: false,
  hasSearched: false,
  withFilters: false,
  records: [],
  totalRecords: 0,
  currentPage: 1,
  pageSize: 20,
  error: null,
}

const slice = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    reset() {
      return initialState
    },
    removeBreadboxItems: filter.removeBreadboxItem,
    toggleSideMenuExpanded: filter.toggleSideMenuExpanded,
    toggleSideMenuEntryExpanded: filter.toggleSideMenuEntryExpanded,
    clearDateFilter: filter.clearDateFilter,
    setSideMenuCheckbox: filter.setSideMenuCheckbox,
    setSideMenuDateFilter: filter.setSideMenuDateFilter,
    addSelectedSuggestion: filter.addSelectedSuggestion
  },
  extraReducers: (builder) => {
    builder.addCase(requestUsers.pending, (state) => {
      state.isFetching = true
      state.hasSearched = true
      state.error = initialState.error
      state.records = initialState.records
      state.pageSize = initialState.pageSize
      state.totalRecords = initialState.totalRecords
      state.currentPage = initialState.currentPage
    })
    builder.addCase(requestUsers.rejected, (state, action: PayloadAction<string | ApplicationError | undefined, any, any, SerializedError>) => {
      state.isFetching = false
      state.hasSearched = true
      state.records = initialState.records
      state.totalRecords = initialState.totalRecords
      state.error = getRejectionError(action, initialState.error)
      state.sideMenuEntryTopMatches = initialState.sideMenuEntryTopMatches

    })
    builder.addCase(requestUsers.fulfilled, (state, action: PayloadAction<AccountActivityResponse>) => {
      const saveAggregation = action.payload.saveAggregation
      state.isFetching = false
      state.hasSearched = true
      state.error = initialState.error
      state.records = action.payload.result.data.records
      state.pageSize = action.payload.pageSize
      state.currentPage = action.payload.page
      state.totalRecords = action.payload.totalRecords
      if (saveAggregation) {
        const aggregation = action.payload.result.data.metadata?.aggregations || {}
        state.sideMenuEntryTopMatches = aggregation
      }
    })
  }
})

export * from './thunks'

export const {
  reset,
  removeBreadboxItems,
  toggleSideMenuEntryExpanded,
  setSideMenuDateFilter,
  setSideMenuCheckbox,
  clearDateFilter,
  addSelectedSuggestion
} = slice.actions

export default slice.reducer
