import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestErrorHandler } from '../../../../services/request'
import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import { AccountActivitySummary, PageResult } from '../../../../types/api'
import { SortList } from '../../../Sort/state'
import accountActivityService from '../../AccountActivityService'
import { getFilters } from '../../../../services/filters'
import accountActivitySideMenuFilters from '../../../../constants/accountActivitySideMenuFilters'
import { getAggregates } from '../../../../services/aggregates'

export interface AccountActivityRequest {
  page: number
  initial_request: boolean
}

export interface AccountActivityResponse {
  totalRecords: number
  pageSize: number
  page: number
  result: PageResult<AccountActivitySummary>
  saveAggregation: boolean
}

export const requestUsers = createAsyncThunk<AccountActivityResponse, AccountActivityRequest, AppAsyncThunkOptions>
  ('Users/requestUsers', async (request, { getState, rejectWithValue }) => {
    const state = getState()
    const usedPage = request.page ? request.page : 1
    const pageSize = state.accountActivity.results.pageSize

    const {
      sortBy,
      sortOrder
    } = state.sort

    const saveAggregation = request.initial_request

    const accountActivitySortBy = sortBy[SortList.AccountActivityResults]
    const accountActivitySortOrder = sortOrder[SortList.AccountActivityResults]

    const sideMenuCheckboxStatus = state.accountActivity.results.sideMenuCheckboxStatus
    const sideMenuDateFilters = state.accountActivity.results.sideMenuDateFilters
    const sideMenuTextMatchesStatus = state.accountActivity.results.sideMenuTextMatchesStatus
    const filters = getFilters(accountActivitySideMenuFilters, sideMenuCheckboxStatus, sideMenuDateFilters, sideMenuTextMatchesStatus)
    const aggregates = getAggregates(saveAggregation, accountActivitySideMenuFilters)


    try {
      const result = await accountActivityService.getAllAccountActivity(usedPage, pageSize, accountActivitySortBy, accountActivitySortOrder, filters, aggregates)
      return {
        page: usedPage,
        pageSize,
        result,
        totalRecords: result.data.totalRecords,
        saveAggregation: request.initial_request
      }
    } catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const updatePage = (page: number): AppThunk => (dispatch) => {
  dispatch(requestUsers({
    initial_request: false,
    page
  }))
}

export const updateUserResults = (): AppThunk => async (dispatch) => {
  dispatch(requestUsers({
    initial_request: false,
    page: 1
  }))
}
